import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import BuildingMenuItem from "../../components/building/BuildingMenuItem";
import { withTranslation } from "react-i18next";
import Loader from "../../constants/Loader";
import { listPageBuildingsByUser } from "../../graphql/queries";
import {
  deleteBuildingWithAddressStormCustom,
  updateBuildingWithAddressCustom,
  createBuildingsWithAddress,
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import Modal from "../../components/Modal/Modal";
import Layout from "../Layout/Layout";
import ItemsTable from "../../components/Table/ItemsTable";
import BuildingFormProvider from "../../components/building/BuildingFormProvider";
import { StatusCodes } from "../../constants/StatusCodes";
import { BUILDING_HEADERS } from "../../constants/TableHeaders";
import { convertBooleansForPython } from "../../utils/utils";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { COGNITO_GROUPS } from "../../constants/DefaultValues";

const BuildingPage = ({t}) => {
  const [buildings, setBuildings] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [createBuildingModalOpen, setCreateBuildingModalOpen] = useState(false);
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  const isDeleteCreateAuthorized = groups?.includes(COGNITO_GROUPS.VIDATECH_ADMIN);

  async function getBuildings() {
    setIsLoading(true);
    try {
      const values = await API.graphql(
        graphqlOperation(listPageBuildingsByUser)
      );
      
      const buildingModels = values.data.listBuildingsByUser.items.map((item) => {
        return {
          id: item.id,
          Name: item.Name,
          Address: item.Address.Street1,
          Phone: item.Phone,
          City: item.Address.City,
          Province: item.Address.Province,
          PostalCode: item.Address.PostalCode,
          Country: item.Address.Country,
          Status: "0",
          RefreshRateInSec: item.RefreshRateInSec,
          IsFetchingWeather: item.IsFetchingWeather,
        };
      });
      setBuildings(buildingModels.sort((a, b) => a.Name.localeCompare(b.Name, undefined, { numeric: true, sensitivity: 'base' })));
    } catch (error) {
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getBuildings();
  }, []);

  const handleCreate = async (building) => {
    try {
      setIsLoading(true);
      setCreateBuildingModalOpen(false);
      building = convertBooleansForPython(building);
      const res = await API.graphql(
        graphqlOperation(createBuildingsWithAddress, { input: building })
      );
      if (res.data.createBuildingWithAddress.statusCode !== StatusCodes.OK) {
        throw new Error(res.data.createBuildingsWithAddress.body.message);
      }
      await getBuildings();
    } catch (error) {
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      let result = await API.graphql(
        graphqlOperation(deleteBuildingWithAddressStormCustom, {
          input: { id: id },
        })
      );
      if (
        result.data.deleteBuildingWithAddressStorm.statusCode !== StatusCodes.OK
      ) {
        throw new Error("Error deleting building");
      }

      await getBuildings();
    } catch (error) {
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    try {
      setIsLoading(true);
      data = convertBooleansForPython(data);
      const result = await API.graphql(
        graphqlOperation(updateBuildingWithAddressCustom, { input: data })
      );
      if (result.data.updateBuildingWithAddress.statusCode !== StatusCodes.OK) {
        throw new Error("Error updating building");
      }
      await getBuildings();
    } catch (error) {
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortBy = (header, sortOrder) => {
    const sortedBuildings = [...buildings];
      if (sortOrder === "asc"){
        sortedBuildings.sort((a, b) => a[BUILDING_HEADERS[header]].localeCompare(b[BUILDING_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' }));
      } else {
        sortedBuildings.sort((a, b) => b[BUILDING_HEADERS[header]].localeCompare(a[BUILDING_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' }));
      }
      setBuildings(sortedBuildings);
  }

  return (
    <Layout
      headerText={t(TRANSLATION_KEYS.BUILDINGS_HEADER_TITLE)}
      showSidebar={true}
      showFooter={false}
      headerButtonText={isDeleteCreateAuthorized && t(TRANSLATION_KEYS.BUILDINGS_CREATE_BUILDING)}
      onHeaderButtonClick={() => setCreateBuildingModalOpen(true)}
    >
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <ItemsTable
          headers={BUILDING_HEADERS}
          onSortBy={handleSortBy}
        >
          {buildings &&
            buildings
              .map((item) => (
                <BuildingMenuItem
                  key={item.id}
                  item={item}
                  handleDelete={handleDelete}
                  handleUpdate={handleUpdate}
                  isDeleteCreateAuthorized={isDeleteCreateAuthorized}
                />
              ))}
        </ItemsTable>
      )}
      <div className="create-building-modal">
        <Modal
          isOpen={createBuildingModalOpen}
          headerText={t(TRANSLATION_KEYS.BUILDINGS_CREATE_BUILDING)}
          onClose={() => setCreateBuildingModalOpen(false)}
        >
          <BuildingFormProvider onSubmit={handleCreate} />
        </Modal>
      </div>
      <div className="error-modal">
        <Modal
          isOpen={errorModalOpen}
          onClose={() => setErrorModalOpen(false)}
        >
          <>
            <img
              src={require("../../img/icon-material-warning@1x.png")}
              alt="warning sign"
            />
            <p>{t(TRANSLATION_KEYS.ERROR)}</p>
          </>
        </Modal>
      </div>
    </Layout>
  );
};
export default withTranslation("buildings")(BuildingPage);
