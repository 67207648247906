import React, { useState } from "react";
import { useNavigate } from "react-router";
import { API, graphqlOperation } from "aws-amplify";
import { createContractorWithAddress, createOwnerWithAddress } from "../../graphql/mutations";
import Layout from "../../containers/Layout/Layout";
import { FormProvider } from "../Form/FormProvider";
import ContractorForm from "./ContractorForm";
import Modal from "../Modal/Modal";
import messageSentImage from "../../img/message-sent.svg";
import warningSignImage from "../../img/warning-sign.svg";
import "./css/Contractor.css";
import Loader from "../../constants/Loader";
import { COGNITO_USER_TYPE } from "../../constants/DefaultValues";
import { StatusCodes } from "../../constants/StatusCodes";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { useTranslation } from "react-i18next";

const Contractor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t(TRANSLATION_KEYS.ERROR));
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    Name: "",
    Street1: "",
    City: "",
    Province: "",
    PostalCode: "",
    Country: "",
    Type: COGNITO_USER_TYPE.CONTRACTOR
  };

  const handleCloseClick = (event) => {
    navigate("/");
  };

  const handleCreateUser = async (user) => {
    const userType = user.Type
    delete user.Type
    if (userType === COGNITO_USER_TYPE.CONTRACTOR) {
      createContractor(user);
    } else if (userType === COGNITO_USER_TYPE.OWNER){
      createOwner(user);
    }
  }

  const createContractor = async (contractor) => {
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(createContractorWithAddress, {
          input: contractor,
        })
      );

      if (response.data.createContractorWithAddress.statusCode === StatusCodes.CONFLICT) {
        setErrorMessage(t(TRANSLATION_KEYS.CREATE_USER_PENDING_APPROVAL))
        setErrorModalOpen(true);
      } else if (response.data.createContractorWithAddress.statusCode === StatusCodes.OK) {
        setConfirmationModal(true);
      } else {
        throw new Error("Error creating contractor");
      }
    } catch (error) {
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const createOwner = async (owner) => {
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(createOwnerWithAddress, {
          input: owner,
        })
      );
      if (response.data.createOwnerWithAddress.statusCode === StatusCodes.CONFLICT) {
        setErrorMessage(t(TRANSLATION_KEYS.CREATE_USER_PENDING_APPROVAL))
        setErrorModalOpen(true);
      } else if (response.data.createOwnerWithAddress.statusCode === StatusCodes.OK) {
        setConfirmationModal(true);
      } else {
        throw new Error("Error creating owner");
      }
    } catch (error) {
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout showFooter={true}>
      <div id="contractor-page">
        {isLoading ? (
          <Loader />
        ) : (
          <FormProvider initialValues={initialValues}>
            <ContractorForm onSubmit={handleCreateUser} />
          </FormProvider>
        )}
      </div>
      <div id="contractor-confirmation">
        <Modal isOpen={confirmationModal} onClose={handleCloseClick}>
          <>
            <img src={messageSentImage} alt="confirmation sign" />
            <p>{t(TRANSLATION_KEYS.CREATE_USER_CONFIRMATION)}</p>
          </>
        </Modal>
        <Modal
          isOpen={errorModalOpen}
          onClose={(e) => setErrorModalOpen(false)}
        >
          <>
            <img
              style={{ width: "50px" }}
              src={warningSignImage}
              alt="warning sign"
            />
            <p>{errorMessage}</p>
          </>
        </Modal>
      </div>
    </Layout>
  );
};

export default Contractor;
