import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../containers/Layout/Layout";
import "./css/Library.css";
import FileExplorer from "../FileExplorer/FileExplorer";
import { useSharedData } from "../../context/SharedProvider";
import { LIBRARY_ALLOWED_FILE_TYPES } from "../../constants/AllowedFileTypes";
import { FILE_STORAGE_LIMIT_O } from "../../constants/DefaultValues";
import Modal from "../Modal/Modal";
import { MAXIMUM_FILE_SIZE } from "../../constants/DataValidation";
import { ERROR_MESSAGES } from "../../constants/Messages";
import VidatechLoader from "../EditVisual/VidatechLoader";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { VALIDATOR_FORMATS } from "../../constants/RequiredValidator";

const Library = () => {
    const { t } = useTranslation(['common', 'library']);
    const { fileStructure, saveImage, isBusy } = useSharedData();
    const [currentFolder, setCurrentFolder] = useState(fileStructure);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(t(TRANSLATION_KEYS.ERROR));
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (fileStructure) {
            if (currentFolder) {
                if (currentFolder.type !== "searchResults") {
                    let newCurrentFolder;
                    if (currentFolder.path === fileStructure.path) {
                        newCurrentFolder = fileStructure;
                    } else {
                        const pathParts = currentFolder.path.split("/").filter(Boolean);
                        pathParts.shift();
                        newCurrentFolder = fileStructure;
                        pathParts.forEach((part) => {
                            newCurrentFolder = newCurrentFolder.content.find((item) => item.name === part);
                        });
                    }
                    setCurrentFolder(newCurrentFolder);
                } else {
                    setCurrentFolder(currentFolder);
                }
            }
            else {
                setCurrentFolder(fileStructure);
            }
        }
    }, [fileStructure, currentFolder]);

    const uploadImage = (e) => {
        if (currentFolder.type !== "searchResults" && currentFolder.path !== "vidatech/") {
            const input = document.createElement("input");
            input.type = "file";
            input.multiple = true;
            input.accept = LIBRARY_ALLOWED_FILE_TYPES.join(",");
            input.onchange = async (e) => {
                setIsLoading(true);
                const filesToSave = [...e.target.files]
                const filesToSaveSize = filesToSave.reduce((acc, file) => acc + file.size, 0);
                const invalidFiles = filesToSave.filter(file => !VALIDATOR_FORMATS.FILE_NAME.regex.test(file.name))


                if (fileStructure.size + filesToSaveSize >= FILE_STORAGE_LIMIT_O) {
                    setErrorMessage(ERROR_MESSAGES.STORAGE_LIMIT_EXCEEDED);
                    setErrorModalOpen(true);
                } else if(invalidFiles.length > 0) {
                    const message = `${t(TRANSLATION_KEYS.LIBRARY_INVALID_FILE_NAME)}`;
                    setErrorMessage(message);
                    setErrorModalOpen(true);
                } else {
                    for (let i = 0; i < filesToSave.length; i++) {
                        if (filesToSave[i].size < MAXIMUM_FILE_SIZE) {
                            await saveFile(filesToSave[i]);
                        } else {
                            setErrorMessage(ERROR_MESSAGES.FILE_SIZE_EXCEEDED);
                            setErrorModalOpen(true);
                            setIsLoading(false);
                        }
                    }
                }
                setIsLoading(false);
            }
            input.click();
        }
    }

    const saveFile = async (file) => {
        if (LIBRARY_ALLOWED_FILE_TYPES.includes(file.type)) {
            // const currentFolder = fileStructure.content.find((item) => item.path === currentFolderPath);
            let fileName = file.name;
            let imageExists = currentFolder.content.find((item) => item.name === fileName);
            let counter = 2;
            while (imageExists) {
                const parts = file.name.split(".");
                fileName = parts[0] + ` (${counter++}).` + parts[1];
                imageExists = currentFolder.content.find((item) => item.name === fileName);
            }
            const base64String = await convertToBase64(file);
            const extension = fileName.split(".").pop();
            let path = currentFolder.path + fileName;
            if (path.startsWith("/library/")) {
                path = path.substring("/library/".length);
            }
            const image = {
                path: path,
                name: fileName,
                data: await convertToObjectUrl(file),
                base64: base64String,
                type: extension,
                size: file.size,
                fullPath: fileStructure.path + path
            };
            await saveImage(image);
            delete image.base64;
            image.path = image.fullPath; //TODO: check if backend shouldn't accept /library/ in the path
            currentFolder.content.push(image);
            currentFolder.size += file.size;
            if (currentFolder.path !== fileStructure.path) {
                fileStructure.size += file.size;
            }

            const updateParentFolderSize = (folder) => {
                const pathParts = folder.path.split("/").filter(Boolean);
                const parentPath = "/" + pathParts.slice(0, pathParts.length - 1).join("/") + "/";
                if (parentPath) {
                    const parentFolder = fileStructure.content.find((item) => item.path === parentPath);
                    if (parentFolder) {
                        parentFolder.size += file.size;
                        updateParentFolderSize(parentFolder);
                    }
                }
            }

            updateParentFolderSize(currentFolder);
            setCurrentFolder({ ...currentFolder });
        }
    }

    async function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(",")[1]);
            reader.onerror = (error) => reject(error);
        });
    }

    async function convertToObjectUrl(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const objectUrl = URL.createObjectURL(file);
                resolve(objectUrl);
            };
            reader.onerror = error => {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    return (
        <Layout
            showSidebar
            headerText={t(TRANSLATION_KEYS.LIBRARY_HEADER_TITLE)}
            headerButtonDisabled={
                isBusy
                || (currentFolder && (currentFolder.type === "searchResults" || (currentFolder.path).startsWith("/library/vidatech/")))
            }
            headerButtonText={t(TRANSLATION_KEYS.LIBRARY_IMPORT_FILE)}
            onHeaderButtonClick={uploadImage}
        >
            <div className="error-modal">
                <Modal
                    isOpen={errorModalOpen}
                    onClose={() => setErrorModalOpen(false)}
                >
                    <>
                        <img
                            src={require("../../img/icon-material-warning@1x.png")}
                            alt="warning sign"
                        />
                        <p>{errorMessage}</p>
                    </>
                </Modal>
            </div>
            <FileExplorer onCurrentFolderChanged={setCurrentFolder} />
            {isLoading && <VidatechLoader />}
        </Layout>
    );
};

export default Library;
