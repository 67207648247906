export const TRANSLATION_KEYS = {
    //Commons
    EDIT: 'common:edit',
    CREATE: 'common:create',
    DELETE: 'common:delete',
    CANCEL: 'common:cancel',
    SAVE: 'common:save',
    CONFIRM: 'common:confirm',
    DOWNLOAD: 'common:download',
    CLOSE_WITHOUT_SAVING: 'common:closeWithoutSaving',
    YES: 'common:yes',
    NO: 'common:no',
    CLOSE: 'common:close',
    ERROR: 'common:error',
    NONE: 'common:none',
    SEND: 'common:send',
    BACK: 'common:back',
    VALIDATION_REQUIRED: 'common:validation.required',
    VALIDATION_EMAIL: 'common:validation.email',
    VALIDATION_MIN_LENGTH: 'common:validation.minlength',
    VALIDATION_MAX_LENGTH: 'common:validation.maxlength',
    VALIDATION_RANGE: 'common:validation.range',
    VALIDATION_MIN: 'common:validation.min',
    VALIDATION_MAX: 'common:validation.max',
    VALIDATION_PHONE_FORMAT: 'common:validation.phoneFormat',
    VALIDATION_POSTAL_CODE_CA: 'common:validation.postalCodeCA',
    VALIDATION_POSTAL_CODE_US: 'common:validation.postalCodeUS',
    VALIDATION_PASSWORD_CONFIRMATION: 'common:validation.passwordConfirmation',
    VALIDATION_PASSWORD_DIFFERENT: 'common:validation.passwordDifferent',
    VALIDATION_INVALID_FORMAT: 'common:validation.invalidFormat',
    VALIDATION_INVALID_CHARACTERS: 'common:validation.invalidCharacters',

    //Sidebar
    SIDEBAR_BUILDINGS: 'sidebar:buildings',
    SIDEBAR_USERS: 'sidebar:users',
    SIDEBAR_VIDATECH_ADMIN: 'sidebar:vidatechAdmin',
    SIDEBAR_MECHANICS: 'sidebar:mechanics',
    SIDEBAR_PRODUCTS: 'sidebar:products',
    SIDEBAR_VISUALS: 'sidebar:visuals',
    SIDEBAR_LIBRARY: 'sidebar:library',
    SIDEBAR_ELEVATOR_ID: 'sidebar:elevatorId',
    SIDEBAR_CONNECT_TO_ELEVATOR: 'sidebar:connectToElevator',
    SIDEBAR_INSERT_ID: 'sidebar:insertId',
    SIDEBAR_GREETINGS: 'sidebar:greetings',
    SIDEBAR_GO_TO_ACCOUNT: 'sidebar:goToAccount',
    SIDEBAR_LOGOUT: 'sidebar:logout',
    SIDEBAR_ABOUT: 'sidebar:about',
    SIDEBAR_CHANGE_LANGUAGE: 'sidebar:changeLanguage',
    SIDEBAR_DOWNLOAD_BID: 'sidebar:downloadBid',
    SIDEBAR_DOWNLOAD_BFG: 'sidebar:downloadBfg',
    SIDEBAR_CONTACT: 'sidebar:contact',

    //Buildings
    BUILDINGS_HEADER_TITLE: 'buildings:headerTitle',
    BUILDINGS_CREATE_BUILDING: 'buildings:createBuilding',
    BUILDINGS_EDIT_BUILDING: 'buildings:editBuilding',
    BUILDING_NAME: 'buildings:headers.name',
    BUILDING_NAME_PLACEHOLDER: 'buildings:buildingNamePlaceholder',
    BUILDING_ADDRESS: 'buildings:headers.address',
    BUILDING_PHONE: 'buildings:headers.phone',
    BUILDING_CITY: 'buildings:headers.city',
    BUILDING_COUNTRY: 'buildings:headers.country',
    BUILDING_STATUS: 'buildings:headers.status',
    BUILDING_ACTION: 'buildings:headers.action',
    BUILDING_DOWNLOAD_BID: 'buildings:downloadBid',
    BUILDING_DOWNLOAD_BFG: 'buildings:downloadBfg',
    BUILDING_REFRESH_RATE: 'buildings:refreshRate',
    BUILDING_FETCH_WEATHER: 'buildings:fetchWeather',
    BUILDING_CONFIRM_DELETE: 'buildings:confirmDelete',

    //FirstResponders
    FIRST_RESPONDERS_HEADER_TITLE: 'firstResponders:headerTitle',
    FIRST_RESPONDERS_CREATE_MECHANIC: 'firstResponders:createMechanic',
    FIRST_RESPONDERS_CLOSED: 'firstResponders:closed',
    FIRST_RESPONDERS_NAME: 'firstResponders:headers.name',
    FIRST_RESPONDERS_PHONE: 'firstResponders:headers.phone',
    FIRST_RESPONDERS_SUNDAY: 'firstResponders:headers.sunday',
    FIRST_RESPONDERS_MONDAY: 'firstResponders:headers.monday',
    FIRST_RESPONDERS_TUESDAY: 'firstResponders:headers.tuesday',
    FIRST_RESPONDERS_WEDNESDAY: 'firstResponders:headers.wednesday',
    FIRST_RESPONDERS_THURSDAY: 'firstResponders:headers.thursday',
    FIRST_RESPONDERS_FRIDAY: 'firstResponders:headers.friday',
    FIRST_RESPONDERS_SATURDAY: 'firstResponders:headers.saturday',
    FIRST_RESPONDERS_ACTIONS: 'firstResponders:headers.actions',
    FIRST_RESPONDERS_EDIT_MECHANIC: 'firstResponders:editMechanic',
    FIRST_RESPONDERS_NAME_LABEL: "firstResponders:name",
    FIRST_RESPONDERS_NAME_PLACEHOLDER: 'firstResponders:namePlaceholder',
    FIRST_RESPONDERS_PHONE_LABEL: 'firstResponders:phone',
    FIRST_RESPONDERS_PHONE_PLACEHOLDER: 'firstResponders:phonePlaceholder',
    FIRST_RESPONDERS_SET_SCHEDULE: 'firstResponders:setSchedule',
    FIRST_RESPONDERS_NOT_AVAILABLE: 'firstResponders:notAvailable',
    FIRST_RESPONDERS_START: 'firstResponders:start',   
    FIRST_RESPONDERS_END: 'firstResponders:end',
    FIRST_RESPONDERS_ERRORS_FETCH_MECHANICS: 'firstResponders:errors.fetchMechanics',
    FIRST_RESPONDERS_ERRORS_DELETE_MECHANIC: 'firstResponders:errors.deleteMechanic',
    FIRST_RESPONDERS_ERRORS_UPDATE_MECHANIC: 'firstResponders:errors.updateMechanic',
    FIRST_RESPONDERS_ERRORS_CREATE_MECHANIC: 'firstResponders:errors.createMechanic',
    FIRST_RESPONDERS_CONFIRM_DELETE: 'firstResponders:confirmDelete',

    //Users
    USERS_HEADER_TITLE: 'users:headerTitle',
    USERS_INVITE_USER: 'users:inviteUser',
    USERS_INVITE: 'users:invite',
    USERS_EDIT_USER: 'users:editUser',
    USERS_NAME: 'users:headers.name',
    USERS_NAME_PLACEHOLDER: 'users:namePlaceholder',
    USERS_EMAIL: 'users:headers.email',
    USERS_EMAIL_PLACEHOLDER: 'users:emailPlaceholder',
    USERS_ROLE: 'users:headers.role',
    USERS_ROLE_PLACEHOLDER: 'users:rolePlaceholder',
    USERS_ACTION: 'users:headers.action',
    USERS_COLLABORATOR: 'users:collaborator',
    USERS_ADMIN: 'users:admin',
    USERS_VIDATECH_ADMIN: 'users:vidatechAdmin',
    USERS_VIEWER: 'users:viewer',
    USERS_DESIGNER: 'users:designer',
    USERS_CONFIRM_DELETE: 'users:confirmDelete',
    USERS_UPDATE_CONFIRMATION: 'users:updateConfirmation',
    USERS_CONFIRM_INVITATION: 'users:confirmInvitation',
    USERS_SELECT_ROLE: 'users:selectRole',
    USERS_ERRORS_FETCH_USERS: 'users:errors.fetchUsers',
    USERS_ERRORS_INVITE_USER: 'users:errors.inviteUser',
    USERS_ERRORS_DELETE_USER: 'users:errors.deleteUser',
    USERS_ERRORS_UPDATE_USER: 'users:errors.updateUser',
    USERS_OWNER: 'users:owner',
    USERS_CONTRACTOR: 'users:contractor',

    //Account
    ACCOUNT_HEADER_TITLE: 'account:headerTitle',
    ACCOUNT_INFORMATION: 'account:information',
    ACCOUNT_NAME: 'account:name',
    ACCOUNT_NAME_PLACEHOLDER: 'account:namePlaceholder',
    ACCOUNT_SELECT_ROLE: 'account:selectRole',
    ACCOUNT_CHANGE_PASSWORD: 'account:changePassword',
    ACCOUNT_CURRENT_PASSWORD: 'account:currentPassword',
    ACCOUNT_CURRENT_PASSWORD_PLACEHOLDER: 'account:currentPasswordPlaceholder',
    ACCOUNT_NEW_PASSWORD: 'account:newPassword',
    ACCOUNT_NEW_PASSWORD_PLACEHOLDER: 'account:newPasswordPlaceholder',
    ACCOUNT_CONFIRM_PASSWORD: 'account:confirmPassword',
    ACCOUNT_CONFIRM_PASSWORD_PLACEHOLDER: 'account:confirmPasswordPlaceholder',
    ACCOUNT_ERRORS_FETCH_USER: 'account:errors.fetchUser',
    ACCOUNT_ERRORS_UPDATE_USER: 'account:errors.updateUser',
    ACCOUNT_ERRORS_PASSWORD_INCORRECT: 'account:errors.passwordIncorrect',
    ACCOUNT_UPDATE_CONFIRMATION: 'account:updateConfirmation',
    ACCOUNT_UPDATE_SUCCESSFUL: 'account:updateSuccessful',
    ACCOUNT_UPDATE_PASSWORD_CONFIRMATION: 'account:passwordUpdateSuccessful',
    ACCOUNT_PASSWORD_REQUIREMENTS: 'account:passwordRequirements',
    ACCOUNT_PASSWORD_REQUIREMENTS_LENGTH: 'account:passwordRequirementsLength',
    ACCOUNT_PASSWORD_REQUIREMENTS_UPPERCASE: 'account:passwordRequirementsUppercase',
    ACCOUNT_PASSWORD_REQUIREMENTS_LOWERCASE: 'account:passwordRequirementsLowercase',
    ACCOUNT_PASSWORD_REQUIREMENTS_NUMBER: 'account:passwordRequirementsNumber',
    ACCOUNT_PASSWORD_REQUIREMENTS_SPECIAL: 'account:passwordRequirementsSpecial',

    //Products
    PRODUCTS_HEADER_TITLE: 'products:headerTitle',
    PRODUCTS_CREATE_PRODUCT: 'products:createProduct',
    PRODUCTS_EDIT_PRODUCT: 'products:editProduct',
    PRODUCTS_LINK_DEVICES_TO_VISUAL: 'products:linkDevicesToVisual',
    PRODUCTS_ID: 'products:headers.id',
    PRODUCTS_NAME: 'products:headers.name',
    PRODUCTS_MODEL: 'products:headers.model',
    PRODUCTS_THEME_LINK: 'products:headers.themeLink',
    PRODUCTS_STATUS: 'products:headers.status',
    PRODUCTS_ACTION: 'products:headers.action',
    PRODUCTS_NAME_PLACEHOLDER: 'products:namePlaceholder',
    PRODUCTS_CONFIRM_DELETE: 'products:confirmDelete',
    PRODUCTS_SELECT_MODEL: 'products:selectModel',
    PRODUCTS_PRODUCT_NAME: 'products:productName',
    PRODUCTS_DOOR_NUMBER: 'products:doorNumber',
    PRODUCTS_DOOR_NUMBER_PLACEHOLDER: 'products:doorNumberPlaceholder',
    PRODUCTS_CABIN_ID: 'products:cabinId',
    PRODUCTS_CABIN_ID_PLACEHOLDER: 'products:cabinIdPlaceholder',
    PRODUCTS_CABIN_ID_TOOLTIP: 'products:cabinIdTooltip',
    PRODUCTS_PHONE_ID: 'products:phoneId',
    PRODUCTS_ERRORS_CREATE_PRODUCT: 'products:errors.createProduct',
    PRODUCTS_ERRORS_FETCH_PRODUCTS: 'products:errors.fetchProducts',
    PRODUCTS_ERRORS_DELETE_PRODUCT: 'products:errors.deleteProduct',
    PRODUCTS_ERRORS_UPDATE_PRODUCT: 'products:errors.updateProduct',
    PRODUCTS_ERRORS_MODEL_AND_ID_UNAVAILABLE: 'products:errors.modelAndIdUnavailable',
    PRODUCTS_ERRORS_UPDATE_SIP: 'products:errors.updateSip',
    PRODUCTS_ERRORS_FETCH_MODELS: 'products:errors.fetchModels',
    PRODUCTS_ERRORS_LINK_VISUAL: 'products:errors.linkVisual',
    PRODUCTS_ERRORS_RESOLUTIONS_DONT_MATCH: 'products:errors.resolutionsDontMatch',
    PRODUCTS_ERRORS_CALLEES_INIT: 'products:errors.calleesInit',
    PRODUCTS_ERRORS_FETCH_RING_GROUP: 'products:errors.fetchRingGroup',
    PRODUCTS_ERRORS_FETCH_SIP_ACCOUNTS: 'products:errors.fetchSipAccounts',
    PRODUCTS_ERRORS_UPDATE_CALLEES: 'products:errors.updateCallees',
    PRODUCTS_ERRORS_MULTIPLE_VIDATECH_ACCOUNTS: 'products:errors.multipleVidatechAccounts',
    PRODUCTS_ERRORS_RESERVED_SIP_NAME: 'products:errors.reservedSipName',
    PRODUCTS_INFORMATION_SHEET: 'products:informationSheet',
    PRODUCTS_PRODUCT_INFORMATION: 'products:productInformation',
    PRODUCTS_ERRORS_FETCH_BUILDING_NAME: 'products:errors.fetchBuildingName',
    PRODUCTS_LINK_VISUAL: 'products:linkVisual',
    PRODUCTS_EDIT_CALLEES: 'products:editCallees',
    PRODUCTS_CONNECT_VOIP: 'products:connectVoip',
    PRODUCTS_MORE_OPTIONS: 'products:moreOptions',
    PRODUCTS_PHYSICAL_ADDRESS: 'products:physicalAddress',
    PRODUCTS_DEVICE_MODEL: 'products:deviceModel',
    PRODUCTS_SCREEN_RESOLUTION: 'products:screenResolution',
    PRODUCTS_IP_ADDRESS: 'products:ipAddress',
    PRODUCTS_MAC_ADDRESS: 'products:macAddress',
    PRODUCTS_SOFTWARE_VERSION: 'products:softwareVersion',
    PRODUCTS_STREAM_IP_ADDRESS: 'products:streamIpAddress',
    PRODUCTS_STREAM_PORT: 'products:streamPort',
    PRODUCTS_GROUP_ID: 'products:groupId',
    PRODUCTS_POWER_CYCLES: 'products:powerCycles',
    PRODUCTS_SIP_ACCOUNT_LINKED: 'products:sipAccountLinked',
    PRODUCTS_LINK_PRODUCT_VISUAL: 'products:linkProductVisual',
    PRODUCTS_LINK: 'products:link',
    PRODUCTS_PRODUCT_CALLEES: 'products:productCallees',
    PRODUCTS_ADD_CALLEE: 'products:addCallee',
    PRODUCTS_PHONE_NUMBER: 'products:phoneNumber',
    PRODUCTS_PHONE_NUMBER_PLACEHOLDER: 'products:phoneNumberPlaceholder',
    PRODUCTS_PHONE_NUMBER_TOOLTIP: 'products:phoneNumberTooltip',
    PRODUCTS_LINK_EMAIL: 'products:linkEmail',
    PRODUCTS_LINK_EMAIL_PLACEHOLDER: 'products:linkEmailPlaceholder',
    PRODUCTS_LINK_EMAIL_TOOLTIP: 'products:linkEmailTooltip',
    PRODUCTS_LINK_NUMBER: 'products:linkNumber',
    PRODUCTS_LINK_NUMBER_PLACEHOLDER: 'products:linkNumberPlaceholder',
    PRODUCTS_LINK_NUMBER_TOOLTIP: 'products:linkNumberTooltip',
    PRODUCTS_SIP_ACCOUNT: 'products:sipAccount',
    PRODUCTS_SETTINGS: 'products:settings',
    PRODUCTS_RING_TIME: 'products:ringTime',
    PRODUCTS_EDIT_VOIP: 'products:editVoip',
    PRODUCTS_SIP_NAME: 'products:sipName',
    PRODUCTS_ACTIVATE_VIDATECH: 'products:activateVidatech',
    PRODUCTS_SELECT_ACCOUNT_TYPE: 'products:selectAccountType',
    PRODUCTS_ACCOUNT_TYPE_PUBLIC: 'products:accountTypePublic',
    PRODUCTS_ACCOUNT_TYPE_PRIVATE: 'products:accountTypePrivate',
    PRODUCTS_PRIMARY_ADDRESS: 'products:primaryAddress',
    PRODUCTS_SECONDARY_ADDRESS: 'products:secondaryAddress',
    PRODUCTS_PBX_USERNAME: 'products:pbxUsername',
    PRODUCTS_PBX_PASSWORD: 'products:pbxPassword',
    PRODUCTS_PBX_EXTENSION: 'products:pbxExtension',
    PRODUCTS_PORT: 'products:port',
    PRODUCTS_ADD_SIP_ACCOUNT: 'products:addSipAccount',
    PRODUCTS_NO_SIP_ACCOUNT: 'products:noSipAccount',

    //Visuals
    VISUALS_HEADER_TITLE: 'visuals:headerTitle',
    VISUALS_CREATE_VISUAL: 'visuals:createVisual',
    VISUALS_NAME: 'visuals:headers.name',
    VISUALS_RESOLUTION: 'visuals:headers.resolution',
    VISUALS_ACTIONS: 'visuals:headers.actions',
    VISUALS_ERRORS_CREATE_VISUAL: 'visuals:errors.createVisual',
    VISUALS_ERRORS_FETCH_VISUALS: 'visuals:errors.fetchVisuals',
    VISUALS_ERRORS_DELETE_VISUAL: 'visuals:errors.deleteVisual',
    VISUALS_ERRORS_FETCH_MODELS: 'visuals:errors.fetchModels',
    VISUALS_ERRORS_SAME_NAME: 'visuals:errors.sameName',
    VISUALS_NAME_LABEL: 'visuals:name',
    VISUALS_NAME_PLACEHOLDER: 'visuals:namePlaceholder',
    VISUALS_SELECT_MODEL: 'visuals:selectModel',
    VISUALS_SCREEN_ORIENTATION: 'visuals:screenOrientation',
    VISUALS_PORTRAIT: 'visuals:portrait',
    VISUALS_LANDSCAPE: 'visuals:landscape',
    VISUALS_CONFIRM_DELETE: 'visuals:confirmDelete',
    VISUALS_800x480: 'visuals:800x480',
    VISUALS_1024x600: 'visuals:1024x600',
    VISUALS_1280x800: 'visuals:1280x800',
    VISUALS_1024x768: 'visuals:1024x768',
    VISUALS_1920x1080: 'visuals:1920x1080',

    //Library
    LIBRARY_HEADER_TITLE: 'library:headerTitle',
    LIBRARY_IMPORT_FILE: 'library:importFile',
    LIBRARY_ERRORS_DOWNLOAD_IMAGE: 'library:errors.downloadImage',
    LIBRARY_ERRORS_FOLDER_EXISTS: 'library:errors.folderExists',
    LIBRARY_ERRORS_INVALID_NAME: 'library:errors.invalidName',
    LIBRARY_ERRORS_STORAGE_LIMIT: 'library:errors.storageLimit',
    LIBRARY_ERRORS_PROCESSING_FILES: 'library:errors.processingFiles',
    LIBRARY_ERRORS_FILE_SIZE_EXCEEDED: 'library:errors.fileSizeExceeded',
    LIBRARY_ERRORS_FETCH_IMAGES: 'library:errors.fetchImages',
    LIBRARY_ERRORS_ADD_FOLDER: 'library:errors.addFolder',
    LIBRARY_ERRORS_ADD_IMAGE: 'library:errors.addImage',
    LIBRARY_ERRORS_DELETE_FOLDER: 'library:errors.deleteFolder',
    LIBRARY_ERRORS_DELETE_ITEM: 'library:errors.deleteItem',
    LIBRARY_ERRORS_DOWNLOAD_FONT: 'library:errors.downloadFont',
    LIBRARY_ERRORS_DOWNLOAD_XML: 'library:errors.downloadXml',
    LIBRARY_ERRORS_DOWNLOAD_IMAGE_FROM: 'library:errors.downloadImageFrom',
    LIBRARY_INVALID_FILE_NAME: 'library:errors.invalidFileName',
    LIBRARY_FILE_USED_IN_VISUAL: 'library:fileUsedInVisual',
    LIBRARY_FOLDER_USED_IN_VISUAL: 'library:folderUsedInVisual',
    LIBRARY_FOLDER_NOT_EMPTY: 'library:folderNotEmpty',
    LIBRARY_SEARCH_RESULTS_NAME: 'library:searchResultsName',
    LIBRARY_SEARCH_RESULTS_PATH: 'library:searchResultsPath',
    LIBRARY_FOLDER_NAME_PLACEHOLDER: 'library:folderNamePlaceholder',
    LIBRARY_SEARCH: 'library:search',
    LIBRARY_SIZE_GB: 'library:sizeInGB',
    LIBRARY_SIZE_MB: 'library:sizeInMB',
    LIBRARY_SIZE_KB: 'library:sizeInKB',
    LIBRARY_SIZE_BYTE: 'library:sizeInbyte',
    LIBRARY_SIZE_NA: 'library:notAvailable',
    LIBRARY_FILE_STORAGE_LIMIT: 'library:fileStorageLimit',
    LIBRARY_STORAGE: 'library:storage',
    LIBRARY_CREATE_FOLDER: 'library:createFolder',
    LIBRARY_FILE_INFORMATION: 'library:fileInformation',
    LIBRARY_TYPE: 'library:type',
    LIBRARY_SIZE: 'library:size',
    LIBRARY_RESOLUTION: 'library:resolution',
    LIBRARY_PATH: 'library:path',
    LIBRARY_SELECT_FILE: 'library:selectFile',

    //EditVisual
    EDIT_VISUAL_SNAP_TO_MIDDLE: 'editVisual:snapToMiddle',
    EDIT_VISUAL_PREVIEW: 'editVisual:preview',
    EDIT_VISUAL_PREVIEW_MODE_MESSAGE: 'editVisual:previewModeMessage',
    EDIT_VISUAL_PUBLISH: 'editVisual:publish',
    EDIT_VISUAL_RETURN_TO_EDITOR: 'editVisual:returnToEditor',
    EDIT_VISUAL_EXPORT_CONFIGURATION: 'editVisual:exportConfiguration',
    EDIT_VISUAL_ADD_CONTENT: 'editVisual:addContent',
    EDIT_VISUAL_BACKGROUND: 'editVisual:background',
    EDIT_VISUAL_IMAGE: 'editVisual:image',
    EDIT_VISUAL_PLAYLIST: 'editVisual:playlist',
    EDIT_VISUAL_BUTTON: 'editVisual:button',
    EDIT_VISUAL_POSITION_INDICATOR: 'editVisual:positionIndicator',
    EDIT_VISUAL_TEXT: 'editVisual:text',
    EDIT_VISUAL_WIDGET_HOUR: 'editVisual:widgetHour',
    EDIT_VISUAL_WIDGET_WEATHER: 'editVisual:widgetWeather',
    EDIT_VISUAL_SELECT_WIDGET: 'editVisual:selectWidget',
    EDIT_VISUAL_PAGE: 'editVisual:page',
    EDIT_VISUAL_SCREEN_PAGES: 'editVisual:screensPages',
    EDIT_VISUAL_CREATE_PAGE: 'editVisual:createPage',
    EDIT_VISUAL_PAGE_CONTENT: 'editVisual:pageContent',
    EDIT_VISUAL_ADD_PAGE: 'editVisual:addPage',
    EDIT_VISUAL_PAGE_NUMBER: 'editVisual:pageNumber',
    EDIT_VISUAL_PROPERTIES: 'editVisual:properties',
    EDIT_VISUAL_PAGE_NAME: 'editVisual:pageName',
    EDIT_VISUAL_ORIENTATION: 'editVisual:orientation',
    EDIT_VISUAL_PORTRAIT: 'editVisual:portrait',
    EDIT_VISUAL_LANDSCAPE: 'editVisual:landscape',
    EDIT_VISUAL_SPECIAL_MESSAGE: 'editVisual:specialMessage',
    EDIT_VISUAL_CUSTOM_BACKGROUND: 'editVisual:customBackground',
    EDIT_VISUAL_CUSTOM_POSITION_INDICATOR: 'editVisual:customPositionIndicator',
    EDIT_VISUAL_CHANGE_IMAGE: 'editVisual:changeImage',
    EDIT_VISUAL_OPTIONS: 'editVisual:options',
    EDIT_VISUAL_ZONE_RESOLUTION: 'editVisual:zoneResolution',
    EDIT_VISUAL_WIDTH: 'editVisual:width',
    EDIT_VISUAL_HEIGHT: 'editVisual:height',
    EDIT_VISUAL_YOUR_IMAGE: 'editVisual:yourImage',
    EDIT_VISUAL_ADD_IMAGE: 'editVisual:addImage',
    EDIT_VISUAL_ZONE_POSITION: 'editVisual:zonePosition',
    EDIT_VISUAL_ZONE_POSITION_INFO: 'editVisual:zonePositionInfo',
    EDIT_VISUAL_NONE_ADD_FILE: 'editVisual:noneAddFile',
    EDIT_VISUAL_BUILD_PLAYLIST: 'editVisual:buildPlaylist',
    EDIT_VISUAL_GENERAL_OPTIONS: 'editVisual:generalOptions',
    EDIT_VISUAL_PLAYLIST_NAME: 'editVisual:playlistName',
    EDIT_VISUAL_PLAYLIST_NAME_PLACEHOLDER: 'editVisual:playlistNamePlaceholder',
    EDIT_VISUAL_ZONE_OPTIONS: 'editVisual:zoneOptions',
    EDIT_VISUAL_IMAGE_OPTION: 'editVisual:imageOption',
    EDIT_VISUAL_DURATION: 'editVisual:duration',
    EDIT_VISUAL_BUTTON_OPTIONS: 'editVisual:buttonOptions',
    EDIT_VISUAL_BUTTON_NAME: 'editVisual:buttonName',
    EDIT_VISUAL_SELECT_IMAGE: 'editVisual:selectImage',
    EDIT_VISUAL_FLOOR_ADDRESS: 'editVisual:floorAddress',
    EDIT_VISUAL_CONFIRM_SAVE: 'editVisual:confirmSave',
    EDIT_VISUAL_CONFIRM_BEFORE_LEAVE: 'editVisual:confirmBeforeLeave',
    EDIT_VISUAL_LEAVE: 'editVisual:leave',
    EDIT_VISUAL_CONFIRM_PUBLISH: 'editVisual:confirmPublish',
    EDIT_VISUAL_CREATING_CONFIGURATION: 'editVisual:creatingConfiguration',
    EDIT_VISUAL_CREATION_DONE: 'editVisual:creationDone',
    EDIT_VISUAL_ITEM_ISSUES: 'editVisual:itemIssues',
    EDIT_VISUAL_REVIEW_ERRORS: 'editVisual:reviewErrors',
    EDIT_VISUAL_ERRORS_FETCH_VISUAL: 'editVisual:errors.fetchVisual',
    EDIT_VISUAL_ERRORS_FETCH_WIDGET: 'editVisual:errors.fetchWidget',
    EDIT_VISUAL_ERRORS_DOWNLOAD_WIDGET_PREVIEW: 'editVisual:errors.downloadWidgetPreview',
    EDIT_VISUAL_ERRORS_DOWNLOAD_WIDGET_DEFINITION: 'editVisual:errors.downloadWidgetDefinition',
    EDIT_VISUAL_ERRORS_WIDGET_DEFINITION_MISSING: 'editVisual:errors.widgetDefinitionMissing',
    EDIT_VISUAL_IMAGE_MISSING: 'editVisual:errors.imageMissing',
    EDIT_VISUAL_ERRORS_WIDGET_FOLDER_MISSING: 'editVisual:errors.widgetFolderMissing',
    EDIT_VISUAL_ERRORS_WIDGET_FOLDER_EMPTY: 'editVisual:errors.widgetFolderEmpty',
    EDIT_VISUAL_ERRORS_WIDGET_DEFINITION_MISSING: 'editVisual:errors.widgetDefMissing',
    EDIT_VISUAL_WARNINGS_FONT_MISSING: 'editVisual:warnings.fontMissing',
    EDIT_VISUAL_ERRORS_DOWNLOAD_FONT_FROM: 'editVisual:errors.downloadFontFrom',
    EDIT_VISUAL_ERRORS_BUTTON_DEFAULT_IMAGE_MISSING: 'editVisual:errors.buttonDefaultImageMissing',
    EDIT_VISUAL_ERRORS_PAGE_INITIALIZATION: 'editVisual:errors.pageInitialization',
    EDIT_VISUAL_ERRORS_SAVING_VISUAL: 'editVisual:errors.savingVisual',
    EDIT_VISUAL_ERRORS_PUBLISHING_VISUAL: 'editVisual:errors.publishingVisual',
    EDIT_VISUAL_WARNINGS_DISPLAY_ISSUES: 'editVisual:warnings.displayIssues',
    EDIT_VISUAL_WARNINGS_BUTTON_OVERLAPPING: 'editVisual:warnings.buttonOverlapping',
    EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP: 'editVisual:warnings.buttonFloorAddressGap',
    EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS: 'editVisual:warnings.buttonDuplicateFloorAddress',
    EDIT_VISUAL_WARNINGS_BUTTON_ISSUES: 'editVisual:warnings.buttonIssues',
    EDIT_VISUAL_WARNINGS_WIDGET_WEATHER_FETCH_WEATHER: 'editVisual:warnings.widgetWeatherFetchWeather',
    EDIT_VISUAL_WARNINGS_MISSING_IMAGES: 'editVisual:warnings.missingImages',
    EDIT_VISUAL_WARNINGS_TEXT_ISSUES: 'editVisual:warnings.textIssues',
    EDIT_VISUAL_WARNINGS_WIDGET_ISSUES: 'editVisual:warnings.widgetIssues',
    EDIT_VISUAL_WARNINGS_PLAYLIST_IMAGE_MISSING: 'editVisual:warnings.playlistImageMissing',
    EDIT_VISUAL_WARNINGS_ITEM_MISSING: 'editVisual:warnings.itemMissing',
    EDIT_VISUAL_CUSTOM_BUTTON: 'editVisual:customButton',
    EDIT_VISUAL_CUSTOM_TEXT: 'editVisual:customText',
    EDIT_VISUAL_CHANGE_UNPRESSED: 'editVisual:changeUnpressed',
    EDIT_VISUAL_CHANGE_PRESSED: 'editVisual:changepressed',
    EDIT_VISUAL_CREATE_POSITION_INDICATOR: 'editVisual:createPositionIndicator',
    EDIT_VISUAL_DIGITS_LABEL: 'editVisual:digitsLabel',
    EDIT_VISUAL_DIGITS_PLACEHOLDER: 'editVisual:digitsPlaceholder',
    EDIT_VISUAL_FONT_FAMILY_LABEL: 'editVisual:fontFamilyLabel',
    EDIT_VISUAL_FONT_FAMILY_PLACEHOLDER: 'editVisual:fontFamilyPlaceholder',
    EDIT_VISUAL_FONT_SIZE_LABEL: 'editVisual:fontSizeLabel',
    EDIT_VISUAL_FONT_SIZE_PLACEHOLDER: 'editVisual:fontSizePlaceholder',
    EDIT_VISUAL_COLOR_LABEL: 'editVisual:colorLabel',
    EDIT_VISUAL_COLOR_PLACEHOLDER: 'editVisual:colorPlaceholder',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_AMBER: 'editVisual:travelInfoColors.amber',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_BLACK: 'editVisual:travelInfoColors.black',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_BLUE: 'editVisual:travelInfoColors.blue',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_DARK_GRAY: 'editVisual:travelInfoColors.darkGray',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_GREEN: 'editVisual:travelInfoColors.green',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_LIGHT_GRAY: 'editVisual:travelInfoColors.lightGray',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_RED: 'editVisual:travelInfoColors.red',
    EDIT_VISUAL_TRAVEL_INFO_COLORS_WHITE: 'editVisual:travelInfoColors.white',
    EDIT_VISUAL_ARROW_POSITION: 'editVisual:arrowPosition',
    EDIT_VISUAL_LEFT: 'editVisual:left',
    EDIT_VISUAL_RIGHT: 'editVisual:right',
    EDIT_VISUAL_DOWN: 'editVisual:down',
    EDIT_VISUAL_CONTENT: 'editVisual:content',
    EDIT_VISUAL_CONTENT_PLACEHOLDER: 'editVisual:contentPlaceholder',
    EDIT_VISUAL_TEXT_NAME_PLACEHOLDER: 'editVisual:textNamePlaceholder',

    //Chatrooom
    CHATROOM_TITLE: 'chatroom:title',
    CHATROOM_DETAILS: 'chatroom:details',
    CHATROOM_RESPONSE_OF_PASSENGER: 'chatroom:responseOfPassenger',
    CHATROOM_TEXT_TO_PASSENGER: 'chatroom:textToPassenger',
    CHATROOM_BUILDING: 'chatroom:building',
    CHATROOM_BUILDING_NAME: 'chatroom:buildingName',
    CHATROOM_BUILDING_ADDRESS: 'chatroom:buildingAddress',
    CHATROOM_ELEVATOR: 'chatroom:elevator',
    CHATROOM_ELEVATOR_ID: 'chatroom:elevatorId',
    CHATROOM_ELEVATOR_NAME: 'chatroom:elevatorName',
    CHATROOM_FIRST_RESPONDERS: 'chatroom:firstResponders',
    CHATROOM_RESPONDERS_ON_DUTY: 'chatroom:respondersOnDuty',
    CHATROOM_TYPE_TO_COMMUNICATE: 'chatroom:typeToCommunicate',

    //Sign in
    SIGNIN_SIGNIN: 'signIn:signIn',
    SIGNIN_CREATE_ACCOUNT: 'signIn:createAccount',
    SIGNIN_SIGNIN_TO_ACCOUNT: 'signIn:signinToAccount',
    SIGNIN_EMAIL: 'signIn:email',
    SIGNIN_EMAIL_PLACEHOLDER: 'signIn:emailPlaceholder',
    SIGNIN_PASSWORD: 'signIn:password',
    SIGNIN_PASSWORD_PLACEHOLDER: 'signIn:passwordPlaceholder',
    SIGNIN_FORGOT_PASSWORD: 'signIn:forgotPassword',
    SIGNIN_CONFIRM_PASSWORD: 'signIn:confirmPassword',
    SIGNIN_CONFIRM_PASSWORD_PLACEHOLDER: 'signIn:confirmPasswordPlaceholder',
    SIGNIN_CONFIRM_ACCOUNT: 'signIn:confirmAccount',
    SIGNIN_MULTI_FACTOR_AUTHENTICATION: 'signIn:multiFactorAuthentication',
    SIGNIN_SCAN_QR_CODE: 'signIn:scanQrCode',
    SIGNIN_ENTER_INFO: 'signIn:enterInfo',
    SIGNIN_CONFIRMATION_CODE: 'signIn:confirmationCode',
    SIGNIN_CONFIRMATION_CODE_PLACEHOLDER: 'signIn:confirmationCodePlaceholder',
    SIGNIN_NEW_PASSWORD: 'signIn:newPassword',
    SIGNIN_NEW_PASSWORD_PLACEHOLDER: 'signIn:newPasswordPlaceholder',
    SIGNIN_TOTP_CONFIRMATION_CODE: 'signIn:totpConfirmationCode',
    
    //Address
    ADDRESS_ADDRESS: 'address:address',
    ADDRESS_ADDRESS_PLACEHOLDER: 'address:addressPlaceholder',
    ADDRESS_CITY: 'address:city',
    ADDRESS_PROVINCE: 'address:province',
    ADDRESS_POSTAL_CODE: 'address:postalCode',
    ADDRESS_POSTAL_CODE_PLACEHOLDER: 'address:postalCodePlaceholder',
    ADDRESS_COUNTRY: 'address:country',

    //CreateUser
    CREATE_USER_TITLE: 'createUser:headerTitle',
    CREATE_USER_SELECT_TYPE: 'createUser:selectType',
    CREATE_USER_OWNER: 'createUser:owner',
    CREATE_USER_CONTRACTOR: 'createUser:contractor',
    CREATE_USER_NAME: 'createUser:name',
    CREATE_USER_PENDING_APPROVAL: 'createUser:pendingApproval',
    CREATE_USER_CONFIRMATION: 'createUser:confirmation'
}