import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import buidlingsEN from '../utils/i18n/locales/en/buildings.json';
import buidlingsFR from '../utils/i18n/locales/fr/buildings.json';
import commonEN from '../utils/i18n/locales/en/common.json';
import commonFR from '../utils/i18n/locales/fr/common.json';
import addressEN from '../utils/i18n/locales/en/address.json';
import addressFR from '../utils/i18n/locales/fr/address.json';
import sidebarEN from '../utils/i18n/locales/en/sidebar.json';
import sidebarFR from '../utils/i18n/locales/fr/sidebar.json';
import usersEN from '../utils/i18n/locales/en/users.json';
import usersFR from '../utils/i18n/locales/fr/users.json';
import productsEN from '../utils/i18n/locales/en/products.json';
import productsFR from '../utils/i18n/locales/fr/products.json';
import firstRespondersEN from '../utils/i18n/locales/en/firstResponders.json';
import firstRespondersFR from '../utils/i18n/locales/fr/firstResponders.json';
import accountEN from '../utils/i18n/locales/en/account.json';
import accountFR from '../utils/i18n/locales/fr/account.json';
import visualsEN from '../utils/i18n/locales/en/visuals.json';
import visualsFR from '../utils/i18n/locales/fr/visuals.json';
import libraryEN from '../utils/i18n/locales/en/library.json';
import libraryFR from '../utils/i18n/locales/fr/library.json';
import editVisualEN from '../utils/i18n/locales/en/editVisual.json';
import editVisualFR from '../utils/i18n/locales/fr/editVisual.json';
import chatroomEN from '../utils/i18n/locales/en/chatroom.json';
import chatroomFR from '../utils/i18n/locales/fr/chatroom.json';
import signInEN from '../utils/i18n/locales/en/signIn.json';
import signInFR from '../utils/i18n/locales/fr/signIn.json';
import createUserEN from '../utils/i18n/locales/en/createUser.json';
import createUserFR from '../utils/i18n/locales/fr/createUser.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    buildings: buidlingsEN,
    common: commonEN,
    address: addressEN,
    sidebar: sidebarEN,
    users: usersEN,
    products: productsEN,
    firstResponders: firstRespondersEN,
    account: accountEN,
    visuals: visualsEN,
    library: libraryEN,
    editVisual: editVisualEN,
    chatroom: chatroomEN,
    signIn: signInEN,
    createUser: createUserEN
  },
  fr: {
    buildings: buidlingsFR,
    common: commonFR,
    address: addressFR,
    sidebar: sidebarFR,
    users: usersFR,
    products: productsFR,
    firstResponders: firstRespondersFR,
    account: accountFR,
    visuals: visualsFR,
    library: libraryFR,
    editVisual: editVisualFR,
    chatroom: chatroomFR,
    signIn: signInFR,
    createUser: createUserFR
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'], // Cache the detected language
    },
    //lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    // Namespaces configuration
    ns: ['common'],
    defaultNS: 'common',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;